const initBlock = async function( block ) {
	const slider = block.find( ".js-featured-posts-slider" )[0];

	if ( !slider ) {
		return;
	}

	const { Swiper } = await import( "swiper" );
	await import( "swiper/css/bundle" );

	new Swiper( slider, {
		slidesPerView: "auto",
		spaceBetween: 20,
		breakpoints: {
			576: {
				slidesPerView: 2,
				spaceBetween: 12,
			},
			768: {
				slidesPerView: 2,
				spaceBetween: 1,
			},
			1200: {
				slidesPerView: 3,
				spaceBetween: 20,
			}
		},
		navigation: false,
		pagination: false,
	} );
};

themeUtils.loadBlock( initBlock, "featured-posts", ".section-featured-posts" );
